var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "branchesSearchModule" },
    [
      _c("form", [
        _c(
          "fieldset",
          [
            _c("legend", [
              _vm._v(_vm._s(_vm.$t("navigation.branchSearch.headline"))),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n                    " +
                  _vm._s(_vm.$t("navigation.branchSearch.text")) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("label", { attrs: { for: "branch-search-module-query" } }, [
              _vm._v(
                _vm._s(_vm.$t("navigation.branchSearch.form.search.label"))
              ),
            ]),
            _vm._v(" "),
            _c("input-field", {
              attrs: {
                id: "branch-search-module-query",
                placeholder: _vm.$t(
                  "navigation.branchSearch.form.search.label"
                ),
                showSpinner: _vm.showSpinner,
                type: "text",
              },
              nativeOn: {
                keyup: function ($event) {
                  return _vm.searchAddress($event)
                },
              },
              model: {
                value: _vm.query,
                callback: function ($$v) {
                  _vm.query = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "query",
              },
            }),
            _vm._v(" "),
            _c("button", { attrs: { type: "submit" } }, [
              _vm._v(
                _vm._s(_vm.$t("navigation.branchSearch.form.search.submit"))
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "branchesSearchModule__list",
          class: { noResults: _vm.branches.length === 0 },
        },
        [
          _c("h5", [
            _vm._v(_vm._s(_vm.$t("navigation.branchSearch.result.headline"))),
          ]),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.branches, function (branch, index) {
              return _c(
                "li",
                {
                  staticClass: "branchesSearchModule__list--item",
                  class: { active: _vm.activeResult === branch },
                },
                [
                  _c(
                    "a",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.activateBranch(index)
                        },
                      },
                    },
                    [
                      _c("icon", { attrs: { icon: "mapPin" } }),
                      _vm._v(" "),
                      _c("span", {
                        domProps: {
                          innerHTML: _vm._s(_vm.getListText(branch)),
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "link-wrap",
            {
              staticClass: "branchesSearchModule__list--allLink",
              attrs: { link: _vm.link },
            },
            [
              _c("icon", { attrs: { icon: "arrowLeft" } }),
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("navigation.branchSearch.result.link")) +
                  "\n            "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.activeResult
        ? _c(
            "link-wrap",
            {
              staticClass: "branchesSearchModule__view",
              attrs: { link: _vm.activeResult.url },
            },
            [
              _c("map-image-tag", {
                attrs: {
                  context: _vm.context,
                  latitude: _vm.activeResult.latitude,
                  longitude: _vm.activeResult.longitude,
                  size: "350x200",
                  zoom: "13",
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text" }, [
                _c("h3", [
                  _vm._v(
                    _vm._s(_vm.$t("branchSearch.list.name.prefix")) +
                      " " +
                      _vm._s(_vm.activeResult.name)
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm.activeResult.address
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.activeResult.address) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeResult.zip || _vm.activeResult.city
                    ? _c("span", [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(_vm.activeResult.zip) +
                            " " +
                            _vm._s(_vm.activeResult.city) +
                            "\n\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _vm.activeResult.phone
                      ? _c(
                          "link-wrap",
                          { attrs: { link: _vm.activeResult.phone } },
                          [
                            _c("icon", { attrs: { icon: "phone" } }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.activeResult.phone) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.activeResult.email
                      ? _c(
                          "mail-wrap",
                          { attrs: { address: _vm.activeResult.email } },
                          [
                            _c("icon", { attrs: { icon: "mail" } }),
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.activeResult.email) +
                                "\n                    "
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("p", [
                  _c("small", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("navigation.branchSearch.view.notice")) +
                        "\n                    "
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }