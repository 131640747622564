

























































































import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {map} from '@labor-digital/helferlein/lib/Lists/map';
import {debouncePromise} from '@labor-digital/helferlein/lib/Misc/debouncePromise';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {Collection} from '@labor-digital/typo3-vue-framework/lib/Core/JsonApi/IdeHelper';
import {Branch} from '../../../../../Interface/Branch';
import {BranchAndGeoLocationService} from '../../../../../Service/BranchAndGeoLocationService';
import InputField from '../../../../Form/InputField/InputField.vue';
import Icon from '../../../../Misc/Icon/Icon.vue';
import MapImageTag from '../../../../Misc/Image/MapImageTag/MapImageTag.vue';
import LinkWrap from '../../../../Misc/Link/LinkWrap/LinkWrap.vue';
import MailWrap from '../../../../Misc/Link/MailWrap/MailWrap.vue';

export default {
    name: 'BranchSearchModule',
    components: {InputField, Icon, LinkWrap, MailWrap, MapImageTag},
    props: {
        context: null as ContentElementContext,
        link: String
    },
    data()
    {
        return {
            query: '',
            result: [],
            radius: 50,
            activeResultPointer: 0,
            showSpinner: false
        };
    },
    computed: {
        branches(): Array<void> | PlainObject<Branch>
        {
            return this.result;
        },
        activeResult()
        {
            return !isEmpty(this.result) ? this.result[this.activeResultPointer] : null;
        }
    },
    mounted()
    {
        document.addEventListener('keydown', this.onKeyDown);
    },
    beforeDestroy(): void
    {
        document.removeEventListener('keydown', this.onKeyDown);
    },
    methods: {
        isArrowKey(event: KeyboardEvent)
        {
            return event.key.startsWith('Arrow');
        },
        onKeyDown(event: KeyboardEvent)
        {
            if (event.key === 'Enter') {
                event.preventDefault();
                if (this.activeResult === null) {
                    return;
                }
                this.goToBranch(this.activeResult);
                return;
            }
            if (event.key === 'ArrowDown' || event.key === 'ArrowRight') {
                this.nextResult();
                return;
            }
            if (event.key === 'ArrowUp' || event.key === 'ArrowLeft') {
                this.prevResult();
                return;
            }
        },
        nextResult()
        {
            if (this.activeResultPointer < this.result.length - 1) {
                this.activeResultPointer++;
            } else {
                this.activeResultPointer = 0;
            }
        },
        prevResult()
        {
            if (this.activeResultPointer > 0) {
                this.activeResultPointer--;
            } else {
                this.activeResultPointer = this.result.length - 1;
            }
        },
        goToBranch(branch: Branch)
        {
            const url = BranchAndGeoLocationService.getContextualBranchLink(branch);
            this.$router.push(url.replace(/^(?:\/\/|[^/]+)*\//, ''));
        },
        searchAddress(event: KeyboardEvent)
        {
            if (this.query.length < 3 || this.isArrowKey(event) || event.key === 'Enter') {
                return;
            }
            this.showSpinner = true;
            const query = this.query;
            debouncePromise('branchSearchModule', () => {
                return this.context.appContext.axios.get('/location/geocode/' + query);
            }, 300, true)
                    .then(response => {
                        if (response.data.length === 0) {
                            return;
                        }

                        // @todo Use all found locations and merge branch results
                        this.searchBranches(response.data[0].latitude, response.data[0].longitude, this.radius)
                            .then(() => {
                                this.showSpinner = false;
                            });
                    });
        },
        searchBranches(latitude: number, longitude: number, radius: string)
        {
            return this
                    .context.appContext.resourceApi
                    .getCollection(
                            'branch',
                            {
                                filter: {
                                    location: latitude + ',' + longitude + ',' + radius,
                                    page: {size: 5}
                                }
                            }
                    )
                    .then((response: Collection) => {
                        this.activeResultPointer = 0;
                        this.result = map(response.getAll(), (v) => v.getAll());
                    });
        },
        activateBranch(branchPointer: number)
        {
            this.activeResultPointer = branchPointer;
        },
        getListText(branch: Branch)
        {
            let text = branch.city + ', ' + (
                    branch.district ? branch.district + ', ' : ''
            ) + branch.zip;
            let regex = new RegExp('(' + this.query + ')', 'gi');

            return text.replace(regex, '<strong>$1</strong>');
        }
    }
};
